import React, { Component } from 'react';
import * as CSS from 'csstype';

const svg = {
  play: 'M22 14v37l37-18.5z',
  pause:
    'M16,51 L26.6666667,51 L26.6666667,14 L16,14 L16,51 Z M37.3333333,14 L37.3333333,51 L48,51 L48,14 L37.3333333,14 Z',
  fullscreen:
    'M19.2857143,37.7857143 L14,37.7857143 L14,51 L27.2142857,51 L27.2142857,45.7142857 L19.2857143,45.7142857 L19.2857143,37.7857143 Z M14,27.2142857 L19.2857143,27.2142857 L19.2857143,19.2857143 L27.2142857,19.2857143 L27.2142857,14 L14,14 L14,27.2142857 Z M45.7142857,45.7142857 L37.7857143,45.7142857 L37.7857143,51 L51,51 L51,37.7857143 L45.7142857,37.7857143 L45.7142857,45.7142857 Z M37.7857143,14 L37.7857143,19.2857143 L45.7142857,19.2857143 L45.7142857,27.2142857 L51,27.2142857 L51,14 L37.7857143,14 Z',
  fullscreen_exit:
    'M14,43.0714286 L21.9285714,43.0714286 L21.9285714,51 L27.2142857,51 L27.2142857,37.7857143 L14,37.7857143 L14,43.0714286 Z M21.9285714,21.9285714 L14,21.9285714 L14,27.2142857 L27.2142857,27.2142857 L27.2142857,14 L21.9285714,14 L21.9285714,21.9285714 Z M37.7857143,51 L43.0714286,51 L43.0714286,43.0714286 L51,43.0714286 L51,37.7857143 L37.7857143,37.7857143 L37.7857143,51 Z M43.0714286,21.9285714 L43.0714286,14 L37.7857143,14 L37.7857143,27.2142857 L51,27.2142857 L51,21.9285714 L43.0714286,21.9285714 Z',
};

interface Props {
  name: 'play' | 'pause' | 'fullscreen' | 'fullscreen_exit';
  size: string;
  color: CSS.Properties['color'];
}

const SVG = (props: Props) => {
  return (
    <svg x='0px' y='0px' width={props.size} height={props.size} viewBox='0 0 64 64'>
      <path fill={props.color} d={svg[props.name]} />
    </svg>
  );
};

export default SVG;
